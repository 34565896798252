<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                银河证券再次来访，非凸AI算法及交易工具生态首次亮相
              </p>
              <div class="fst-italic mb-2">2023年5月15日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >银河证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/37/01-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，银河证券领导一行再次莅临非凸科技上海总部参观指导，就行业发展现状、机构客户需求、算法策略逻辑、非凸算法生态等方面展开了深入交流，诸多业务问题现场讨论，诚意与干货满满。与此同时，银河证券也对非凸的技术实力和讲解专业度表示了赞赏。
                  </p>
                  <p>
                    作为国内最早一批进行量化交易平台建设的银河证券，正着力发展机构业务，加强与机构客户的业务合作。近年来，银河证券持续探索算法交易领域的产品应用，技术革新不断，凭借其过硬的综合服务实力，高效专业的执行能力，启明iTrade平台智能算法日股基交易量最高单日突破30亿元。其组织的“走进算法商”活动，搭建了交流平台，促进了经验分享，推动了行业金融科技进步，营造了行业金融科技创新氛围，也获得了行业一致好评。非凸科技很荣幸再次得以莅临指导，与银河证券的伙伴们进行了一次深度的面对面交流。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/37/02-银河证券.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技商务总监朱家辉介绍了算法交易发展历程、公司发展情况、主要业务及客户需求等。朱家辉表示，“目前A股算法交易渗透率已超20%，处在美国2003-2004年阶段，未来将进入高增长阶段。从行业格局来看，目前全市场提供算法交易，一类是券商自研，一类是第三方算法服务商。非凸科技在算法交易领域深耕多年，主要优化各种交易环节，提高交易效率，提升交易执行完成度，降低市场冲击成本，帮助机构客户获取更多超额收益。”
                  </p>
                  <p>
                    关于智能算法的性能，越来越多管理人更关心的是使用方便度、市场影响力以及交易效率，其后才是一致的执行性能、更好的交易价格等因素。“不是所有的管理人都看重绩效表现，在交易量变大之后，他们更在意的是系统稳定性以及绩效方差。”朱家辉补充道。未来，非凸科技将与银河证券一起同行、共同奔赴，推动智能算法与交易场景的深度协作，加速人工智能在金融科技场景中的应用拓展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/37/03-非凸科技.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技联合创始人&CTO李佐凡从策略逻辑的角度作了分享。李佐凡表示，“在行情方面，我们做了很多优化，包括全链路的低延迟、行情的稳定性等；在总控方面，我们做了严密风控，并严格遵循交易所规则；在预测方面，我们做了多种组合模型，来预测股票市场是长期上涨下跌，还是短期上涨下跌；在策略方面，如果客户提供很长的母单，那么我们预测的数据就会更多，选择的机会也就增多。现在每天要做的事情就是微调策略模型，这就涉及到高频之间的互相博弈。”
                  </p>
                  <p>
                    最后，非凸科技机构业务经理周升详细介绍了非凸算法生态框架体系。非凸科技始终秉持着科技赋能交易的初衷，在不断精进算法产品的同时，也在深入观察行业发展态势，倾听客户诉求，搭建功能丰富、架构先进、高性能、高可用的非凸算法生态。周升介绍道，“非凸算法生态围绕非凸算法总线系统，从交易场景的真实需求出发，结合自身算法优势打造了一系列模块化高级系统组件，包括高级绩效分析系统、回测系统、仿真系统、实时风险管理、运维监控系统等，致力于为券商等大型金融机构提供全套的一站式算法业务解决方案。与此同时，非凸科技希望通过打造产品生态的形式来提升行业效率、释放行业能量、推动行业发展。”
                  </p>
                  <p>
                    非凸科技欢迎与优秀的合作伙伴共讨方案、共克难题，将全力为每一位伙伴交付解决需求的钥匙，共同探索AI算法生态的无限可能。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/37/04-合照.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技将持续坚守客户第一的初心，为金融机构提供更优质的产品和服务。也将积极参与金融市场基础设施建设，为金融机构提供更强的算力支持，持之以恒、砥砺创新，推动量化科技行业创新发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News37",
};
</script>

<style></style>
